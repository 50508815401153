<template>
    <view-address
        v-if="!editAddress"
        v-bind="$attrs"
        :label="label"
        :address="value"
        @edit="editAddress = true"
    />
    <edit-address
        v-else
        :address="value"
        v-bind="$attrs"
        :label="label"
        v-on="$listeners"
        @cancel="editAddress = false"
        @save="saveInfo"
    >
        <template v-slot:copy-from-billing>
            <slot name="copy-from-billing" />
        </template>
    </edit-address>
</template>

<script>
import ViewAddress from './view_address'
import EditAddress from './edit_address'

export default {
    name: `AccountAddressRoot`,
    components: {
        ViewAddress,
        EditAddress
    },

    props: {
        value: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        label: {
            type: String,
            default: () => `Address`
        }
    },

    data: () => ({
        editAddress: false
    }),

    methods: {
        saveInfo() {
            this.editAddress = false
        }
    }
}
</script>
