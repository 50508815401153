<template>
    <view-user-info v-if="!editInfo" v-bind="$attrs" edit @edit="editInfo = true" />
    <edit-user-info v-else v-bind="$attrs" @save="saveInfo" @cancel="editInfo = false" />
</template>

<script>
import EditUserInfo from './edit_user_info'
import ViewUserInfo from './view_user_info'

export default {
    name: `AccountUserInfoRoot`,
    components: {
        EditUserInfo,
        ViewUserInfo
    },
    inheritAttrs: false,
    data: () => ({
        editInfo: false
    }),

    methods: {
        saveInfo(data) {
            this.$emit(`save`, data)
            this.editInfo = false
        }
    }
}
</script>
