<template>
    <v-card v-bind="$attrs" class="pa-4 d-flex" :disabled="false">
        <div class="d-flex flex-column mr-auto">
            <div class="headline">{{ label }}</div>

            <v-textarea
                style="margin-top: unset;"
                dense
                flat
                no-resize
                rows="1"
                solo
                auto-grow
                single-line
                readonly
                hide-details
                :value="uiAddress"
            />
        </div>
        <div>
            <!-- hidden until tested -->
            <!-- <v-icon v-if="validLocation" color="primary" @click="map">
                {{ mdiMapMarker }}
            </v-icon> -->
            <v-icon color="primary" @click="emitEdit">
                {{ mdiPencil }}
            </v-icon>
        </div>
    </v-card>
</template>

<script>
import { mdiMapMarker, mdiPencil } from '@mdi/js'
export default {
    name: `AccountViewAddressMinimized`,
    props: {
        address: {
            type: Object,
            required: true
        },
        label: {
            type: String,
            default: () => `Address`
        }
    },
    data: () => ({
        mdiMapMarker,
        mdiPencil
    }),
    computed: {
        validLocation() {
            // Must at least be on Earth
            return this.address.country
        },
        uiAddress() {
            const {
                firstName = ``,
                lastName = ``,
                company = ``,
                line1 = ``,
                line2 = ``,
                city = ``,
                state = ``,
                postalCode = ``,
                country = ``
            } = this.address

            let displayAddy = ``
            displayAddy +=
                firstName || lastName ? `${firstName} ${lastName}\n` : ``
            displayAddy += company ? `${company}\n` : ``
            displayAddy += line1 ? `${line1}\n` : ``
            displayAddy += line2 ? `${line2}\n` : ``
            displayAddy += `${city} ${state} ${postalCode} ${country}`

            return displayAddy
        }
    },
    methods: {
        map() {
            window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    this.uiAddress
                )}`
            )
        },
        emitEdit() {
            this.$emit(`edit`)
        }
    }
}
</script>
